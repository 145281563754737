/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "bootstrap/dist/css/bootstrap.min.css"
import "react-modal-video/scss/modal-video.scss"

import "./src/styles/global.css"
import "typeface-open-sans"
import "typeface-merriweather"
